import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import "./Comment.css";
import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  IconButton,
  Skeleton,
} from "@mui/material";
import {
  Comment,
  CommentOutlined,
  ContentCopyRounded,
  CopyAll,
  Download,
  DragHandleOutlined,
  FavoriteBorder,
  OpenInNewOutlined,
  Reply,
  Send,
  SendToMobile,
} from "@mui/icons-material";

import { formatNumber } from "./Helpers";
import { ArrowDownOnSquareIcon, ArrowTopRightOnSquareIcon, DocumentDuplicateIcon, HeartIcon } from "@heroicons/react/24/outline";
// import { isMobile } from "react-device-detect";


const CommentComponent = React.memo((props) => {
  const {
    comment,
    selected,
    selectMode,
    selectOnHover,
    setSelectMode,
    handleCommentSelection,
    dragMode,
    previewMode,
    screenShot,
    selectable,
    goToPostComment,
    exportIndividualComment,
    setCopied,
    platform,
    isMobile,
    showAsReply,
    small
  } = props;

  const [bitmapData, setBitmapData] =  React.useState(null);
  const [exporting, setExporting] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const bitmapToBase64 = (bitmapData, width, height) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.createImageData(width, height);
    
    // Fill the ImageData with the bitmap data
    for (let i = 0; i < bitmapData.length; i++) {
      imageData.data[i] = bitmapData[i];
    }

    // Set the ImageData on the canvas
    ctx.putImageData(imageData, 0, 0);

    // Convert canvas to base64
    return canvas.toDataURL('image/png');
  };

  const copyToClipboard = (comment) => {
    // take selected comment text and copy to clipboard
    setCopied(true);

    let text = `@${comment.username.replace("@", "")}: ${comment.text}`;
    // Copy data to clipboard
    navigator.clipboard
      .writeText(text)
      .catch((error) => console.error("Unable to copy:", error));
  };

  const handleImageExport = async () => {
    setExporting(true); 
    await exportIndividualComment(comment); 
    setExporting(false);
  }

  const fetchAndConvertToBitmap = async(imageUrl) => {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = 150;
      canvas.height = 150;
      const ctx = canvas.getContext('2d');

      const drawPlaceholder = (context) => {
        context.save();
        context.beginPath();
        context.arc(75, 75, 75, 0, Math.PI * 2, true);
        context.closePath();
        context.clip();

        // Fill background
        context.fillStyle = stringToColor(username);
        context.fillRect(0, 0, 150, 150);

        // Add text with consistent styling
        context.fillStyle = '#FFFFFF';
        context.font = '600 65px -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif'; // Match MUI's font
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(username.charAt(0).toUpperCase(), 75, 78); // Slight vertical adjustment for optical centering

        context.restore();
      };

      if (!imageUrl || imageUrl === "") {
        drawPlaceholder(ctx);
        setBitmapData(canvas.toDataURL('image/png'));
      } else {
        const img = new Image();
        img.crossOrigin = 'Anonymous';

        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = `${imageUrl}?t=${Date.now()}`;
        }).catch(() => {
          drawPlaceholder(ctx);
        });

        if (img.complete) {
          ctx.save();
          ctx.beginPath();
          ctx.arc(75, 75, 75, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(img, 0, 0, 150, 150);
          ctx.restore();
        }

        setBitmapData(canvas.toDataURL('image/png'));
      }
    } catch (error) {
      console.error('Error in fetchAndConvertToBitmap:', error);
    }
  };

  React.useEffect(() => {
    if (comment) {
      fetchAndConvertToBitmap(comment.userAvatar || "");
    }
  }, [comment]);

  const showHover = () => {
    if (selectOnHover) {
      setHovered(true);
    }
  };

  const hideHover = () => {
    if (selectOnHover) {
      setHovered(false);
    }
  };

  const stringToColor = (string) => {
    if (!string) return '#96CEB4'; // default color if string is empty
    
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    const colors = [
      '#FF6B6B', // coral red
      '#4ECDC4', // turquoise
      '#45B7D1', // sky blue
      '#96CEB4', // sage
      '#FFEEAD', // cream
      '#D4A5A5', // dusty rose
      '#9B59B6', // purple
      '#3498DB', // blue
      '#E67E22', // orange
      '#2ECC71'  // green
    ];
    
    const index = Math.abs(hash % colors.length);
    return colors[index];
  };
  const CircularAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: "50%",
    height: props => props.small ? 25 : 40,
    width: props => props.small ? 25 : 40,
    marginRight: 14,
    fontWeight: 600,
    fontSize: props => props.small ? '12px' : '16px',
  }));
  
  let username = comment ? platform == "x" && 
  comment.creatorName ? comment.creatorName : (comment.username ? comment.username : "Unknown User") : "Unknown User";
  
  return comment ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        pt: small ? 1 : 3,
        pb: small ? 1 : 3,
        cursor: "pointer",
        position: "relative",
        pr:2,
      }}
      onMouseEnter={showHover}
      onMouseLeave={hideHover}
      // onClick={showSelect}
    >
      {hovered ? (
        <Box sx={{ position: "absolute", right: 0, top: 10 }}>
          <ButtonGroup
            size="small"
            sx={{
              background: "#f7f7f7",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "5px 10px",
              gap: ".5rem",
              borderRadius: 30,
            }}
          >
            {exporting ? (
              <Box
                sx={{
                  height: 22,
                  width: 22,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={16} />
              </Box>
            ) : (
              <Tooltip title="Download Image" placement="top">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleImageExport}
                >
                  <ArrowDownOnSquareIcon style={{ height: 20, width: 20, color:"#5C6078" }} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Copy Text" placement="top">
              <IconButton
                color="primary"
                size="small"
                onClick={() => copyToClipboard(comment)}
              >
                <DocumentDuplicateIcon style={{ height: 20, width: 20, color:"#5C6078" }} />
              </IconButton>
            </Tooltip>
            {goToPostComment ? (
              <Tooltip title="Go To Post" placement="top">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => goToPostComment(comment)}
                >
                  <ArrowTopRightOnSquareIcon style={{ height: 20, width: 20, color:"#5C6078" }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </ButtonGroup>
        </Box>
      ) : null}

      {selectMode || hovered || selected ? (
        <Box sx={{ width: "4rem", height: "3rem", display:"flex", alignItems:"center" }}>
          <Checkbox
            color="primary"
            size="small"
            checked={selected}
            onChange={handleCommentSelection}
          />
        </Box>
      ) : dragMode ? (
        <Box sx={{ width: "4rem", height: "3rem", display:"flex", alignItems:"center" }}>
          <DragHandleOutlined sx={{ fontSize: isMobile ? 18 : 25 }} />
        </Box>
      ) : selectable ? (
        <Box sx={{ width: isMobile ? 0 : "4rem", height: "3rem", display:"flex", alignItems:"center" }}/>
      ) : null}


{(showAsReply) ? (
        <Box sx={{ width: "3rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
        </Box>
) : null}



      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "top",
          width: "100%",
        }}
      >
       
       <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "top",
              mt: 1,
            }}

          > 
          {(bitmapData && screenShot) ? (
            <CircularAvatar src={bitmapData} small={small} />
          ) : (
            <CircularAvatar 
              sx={{
                bgcolor: !comment.userAvatar ? stringToColor(username) : undefined,
                fontWeight: 600,
              }}
              src={comment.userAvatar || undefined}
              small={small}
            >
              {!comment.userAvatar && username.charAt(0).toUpperCase()}
            </CircularAvatar>
          )}

          </Box>
       
       

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 0.3,
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              variant="h5"
              align="left"
              style={{
                cursor: "pointer",
                fontSize: (isMobile || small) && !screenShot ? 12 : 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: isMobile ? 180 : 250,
                fontWeight: 600,
              }}
              // onClick={
              //   comment.userlink && !dragMode
              //     ? () =>
              //         window.open(
              //           "https://tiktok.com" + comment.userlink,
              //           "_blank"
              //         )
              //     : null
              // }
            >
              {username} 
            </Typography>

            {comment.isUserVerified || comment.verifiedUser ? (
              <Box sx={{ display: "flex", alignItems: "center", ml: -0.5 }}>
                <img
                  src="/user_verified.png"
                  alt="verified user"
                  style={{ height: 16, width: 16 }}
                />
              </Box>
            ) : null}
            <Typography
              variant="h5"
              align="left"
              style={{
                cursor: "pointer",
                fontSize: (isMobile || small) && !screenShot ? 14 : 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "#777",
                fontWeight: 500,
                fontSize: 14,
                maxWidth: isMobile ? 180 : 250,
              }}
              // onClick={
              //   comment.userlink && !dragMode
              //     ? () =>
              //         window.open(
              //           "https://tiktok.com" + comment.userlink,
              //           "_blank"
              //         )
              //     : null
              // }
            >
              {platform == "x" && comment.username}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordSpacing: "normal",

              }}
            >
              <Typography
                variant="body1"
                align="left"
                component="p"
                sx={{ fontSize: (isMobile || small) ? 14 : 14 }}
              >
                {(() => {
                  // Get the text from the appropriate location in the comment object
                  const commentText = comment.text || comment.comment?.text || comment?.comment?.comment?.text || "";
                  
                  // If we have text, split and process it
                  if (commentText) {
                    return commentText.split(" ").map((word, index) => {
                      if (word.startsWith("@")) {
                        return (
                          <span key={index} style={{ color: "#4728c4" }}>
                            {word}{" "}
                          </span>
                        );
                      } else {
                        return word + " ";
                      }
                    });
                  }
                  
                  // If no text is found, return empty string
                  return "";
                })()}
              </Typography>
            </Box>
          </Box>
        </Box>
              <Box sx={{ flexGrow: 1 }} />

              {comment.engagement &&
              comment.engagement.likes &&
              !isNaN(comment.engagement.likes) &&
              isFinite(comment.engagement.likes) ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    ml: 2,
                  }}
                >
                  <HeartIcon
                    style={{ color: "#999", height: (isMobile || small) ? 18 : 22 }}
                  />
                  <Typography variant="caption" color="textSecondary">
                    {formatNumber(comment.engagement.likes)}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ m: 3, width: 20 }} />
              )}
      </Box>
    </Box>
  ) : null;
});


export default CommentComponent;

