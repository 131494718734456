import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Box,
  Chip,
  Container,
  Paper,
  createTheme,
  ThemeProvider,
  Link
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CrownIcon from '@mui/icons-material/EmojiEvents';
import { siftsyTheme } from './siftsytheme';
import { Check, CheckCircleTwoTone } from '@mui/icons-material';
import { create, transform } from 'lodash';

import { loadStripe } from '@stripe/stripe-js';
import { isMobile } from 'react-device-detect';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(STRIPE_KEY);

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const StripePlanPricing = (props) => {
  const [billingInterval, setBillingInterval] = useState('month');
  const [planTier, setPlanTier] = useState('10');
  
  const { teamData, user } = props;

  const tiers = {
    '10': 'Up to 10 accounts',
    '25': 'Up to 25 accounts',
    '50': 'Up to 50 accounts',
    '100': 'Up to 100 accounts',
     '150': 'Up to 150 accounts',
    '200': 'Up to 200 accounts'
  };



  const plans = [
    // {
    //   id: 'solo',
    //   name: 'Solo',
    //   active: teamData.plan && teamData.plan.name.toLowerCase().includes("solo"), 
    //   description: 'For Individuals\nMaking Moves',
    //   features: [
    //     'All Supported Platforms',
    //     'Analyze up to 3 mos post-publish',
    //     'Up to 500 comments / post',
    //     '1 member'
    //   ],
    //   prices: {
    //     '10': {
    //       month: { amount: 129, priceId: 'price_1QFTKoE0RiUEYnZSQzGq7iK0' },
    //     //   year: { amount: 290, priceId: 'price_solo_10_yr' }
    //     },
    //     '25': {
    //         month: { amount: 219, priceId: 'price_1QFTLLE0RiUEYnZSHbxCHopS' },
    //         // year: { amount: 490, priceId: 'price_solo_25_yr' }
    //     },
    //     '50': {
    //         month: { amount: 319, priceId: 'price_1QFTLWE0RiUEYnZSKZmNV0lW' },
    //         // year: { amount: 490, priceId: 'price_solo_25_yr' }
    //     },
    //     '100': {
    //         month: { amount: 519, priceId: 'price_1QFTLhE0RiUEYnZSzdo0SOqq' },
    //         // year: { amount: 490, priceId: 'price_solo_25_yr' }
    //     },
    //     '150': {
    //         month: { amount: 719, priceId: 'price_1QFTM1E0RiUEYnZSE9ZCStDg' },
    //         // year: { amount: 490, priceId: 'price_solo_25_yr' }
    //     },
    //     '200': {
    //         month: { amount: 919, priceId: 'price_1QFTM9E0RiUEYnZSuTUiO3L5' },
    //         // year: { amount: 490, priceId: 'price_solo_25_yr' }
    //     }
    //   }
    // },
    {
      id: 'agile',
      name: 'Agile',
      active: teamData.plan && teamData.plan.name.toLowerCase().includes("agile"), 
      description: 'For Small Teams Running\nLean, Effective Campaigns',
      features: [
        'All Supported Platforms',
        'Analyze up to 3 mos post-publish',
        'Up to 500 comments / post',
        '3 team members'
      ],
      prices: {
        '10': {
          month: { amount: 199, priceId: 'price_1QKaUVE0RiUEYnZSNNxQ1k2f' },
        //   year: { amount: 290, priceId: 'price_solo_10_yr' }
        },
        '25': {
            month: { amount: 289, priceId: 'price_1QKaUVE0RiUEYnZSb3x9G2ta' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '50': {
            month: { amount: 379, priceId: 'price_1QKaUVE0RiUEYnZSZYKmf3nP' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '100': {
            month: { amount: 589, priceId: 'price_1QKaUVE0RiUEYnZSajed3oO1' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '150': {
            month: { amount: 789, priceId: 'price_1QKaUVE0RiUEYnZS0xNynQ1t' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '200': {
            month: { amount: 989, priceId: 'price_1QKaUVE0RiUEYnZS2Cp1NMOV' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        }
      }
    },
    {
      id: 'growth',
      name: 'Growth',
      description: 'For Midsize Teams\nIncreasing Their Footprint',
      active: teamData.plan && teamData.plan.name.toLowerCase().includes("growth"), 
      popular: true,
      features: [
        'All Supported Platforms',
        'Analyze up to 12 mos post-publish',
        'Up to 1K comments / post',
        '5 team members'
      ],
      prices: {
        '10': {
          month: { amount: 499, priceId: 'price_1QKfGoE0RiUEYnZSs93ZN47i' },
        //   year: { amount: 290, priceId: 'price_solo_10_yr' }
        },
        '25': {
            month: { amount: 589, priceId: 'price_1QKfH0E0RiUEYnZSVkJBqWGZ' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '50': {
            month: { amount: 689, priceId: 'price_1QKfHBE0RiUEYnZSbA5CzPmR' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '100': {
            month: { amount: 889, priceId: 'price_1QKfHSE0RiUEYnZSTYQ3Gcjv' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '150': {
            month: { amount: 1089, priceId: 'price_1QKfHfE0RiUEYnZS7OUneGig' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '200': {
            month: { amount: 1289, priceId: 'price_1QKfHpE0RiUEYnZSkn2Ak6a2' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        }
      }
    },
    {
      id: 'powerhouse',
      name: 'Powerhouse',
      description: 'For Pro Influencer Teams\nOperating At Scale',
      active: teamData.plan && teamData.plan.name.toLowerCase().includes("powerhouse"), 
      features: [
        'All Supported Platforms',
        'Analyze up to 18 mos post-publish',
        'Up to 2.5K comments / post',
        '8 team members'
      ],
      prices: {
        '10': {
          month: { amount: 1299, priceId: 'price_1QKfJKE0RiUEYnZSEaEPtiQd' },
        //   year: { amount: 290, priceId: 'price_solo_10_yr' }
        },
        '25': {
            month: { amount: 1389, priceId: 'price_1QKfJUE0RiUEYnZSmxdjnywh' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '50': {
            month: { amount: 1489, priceId: 'price_1QKfJhE0RiUEYnZSPdOvSUNy' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '100': {
            month: { amount: 1689, priceId: 'price_1QKfJzE0RiUEYnZSij3Lte4o' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '150': {
            month: { amount: 1889, priceId: 'price_1QKfK8E0RiUEYnZSWQc42UKb' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        },
        '200': {
            month: { amount: 2089, priceId: 'price_1QKfKHE0RiUEYnZSSjlmx7j3' },
            // year: { amount: 490, priceId: 'price_solo_25_yr' }
        }
      }
    },
  ];

  const handleTierChange = (planId, tier) => {
    setPlanTier(tier)
  };

  const handleSubscribe = async (priceId) => {
    // In a real implementation, you would:
    // 1. Call your backend to create a Stripe Checkout Session
    // 2. Redirect to the Checkout Session URL
    console.log(`Subscribe to price: ${priceId}`);

    console.log(STRIPE_KEY);

    if(process.env.REACT_APP_STRIPE_TEST_PRICE_ID){
        priceId = process.env.REACT_APP_STRIPE_TEST_PRICE_ID;
    }
    

    createCheckoutSession(priceId);



  };


async function redirectToCheckout(sessionId) {
  const stripe = await stripePromise;

  // Redirects to the checkout page
  const { error } = await stripe.redirectToCheckout({
    sessionId: sessionId,
  });

  if (error) {
    console.error("Stripe Checkout Error:", error.message);
  }
}

// Example usage

  const createCheckoutSession = async (priceId) => {
    const response = await fetch(ENDPOINT + '/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: priceId, // Replace with the actual price ID
        client_reference_id: teamData.id, // Replace with the actual client reference ID
        customer_email: user.primaryEmailAddress.emailAddress, // Optional: Replace with the actual customer email
        teamId: teamData.id, // Optional: Replace with the actual team ID
        teamName: teamData.name, // Optional: Replace with the actual team name
        // cancel url should be current page
        cancel_url: window.location.href,
      }),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error:', errorData.error);
      return;
    }

    const data = await response.json();
    console.log('Checkout session ID:', data.sessionId);

    //open data.url in a new tab
    window.open(data.url, '_blank');



    // redirectToCheckout(data.sessionId);

  };

  const theme = createTheme(siftsyTheme);

  return (
    <Box sx={{width:"100%"}}>

     <Box sx={{ mb:4,}}>
        <Typography align="left" gutterBottom color="primary" fontWeight={700} letterSpacing={-2} 
        fontSize={isMobile ? 26 : 40}>  
          Team pricing & features
        </Typography>
        {/* <Paper 
          elevation={0} 
          sx={{ 
            display: 'inline-flex', 
            p: 1, 
            borderRadius: 2,
            backgroundColor: 'background.paper'
          }}
        >
          <RadioGroup
            row
            value={billingInterval}
            onChange={(e) => setBillingInterval(e.target.value)}
            sx={{ my: 2 }}
          >
            <FormControlLabel 
              value="month" 
              control={<Radio />} 
              label="Monthly billing" 
            />
            <FormControlLabel 
              value="year" 
              control={<Radio />} 
              label="Annual billing" 
            />
          </RadioGroup>
        </Paper> */}
      </Box>

      <Grid container spacing={2}>
        {plans.map((plan, index) => (
          <Grid item xs={12} md={6} lg={4} key={plan.id}>
            <Card 
              elevation={plan.popular ? 8 : 0}
              sx={{ 
                borderRadius: 4,
                border:"1px solid #ccc", 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)'
                },
            
              }}
            >
                {plan.active ?

                  <Box sx={{ position: 'absolute', top: 10, right:10}}>
                    <Chip 
                    icon={<CheckCircleOutlineIcon sx={{fill:"#4728c4"}} />}
                    label="Active" sx={{background:"#cdbdee", color:"#4728c4"}} />
                  </Box>

                

                :
                plan.popular && (
                    <Box sx={{ position: 'absolute', top: 20, left: -20, transform : 'rotate(-45deg)', background:"#fff", width:120 }}>
                    <Typography variant="body1" color="primary" fontWeight={700} align="center" fontSize={12}>
                        POPULAR
                    </Typography>
                    </Box>
                )}

                

              <CardHeader
                title={plan.name}
                titleTypographyProps={{ letterSpacing:-1, align: 'center', fontSize:25, fontWeight:700, color: plan.name === "Solo" ? "primary" : "white" }}
                sx={{backgroundColor: index == 2 ? "#4728c4" : index == 1 ? "#796ef8" : "#ccbaec"}}
              />

              <CardContent sx={{ flexGrow: 1 }}>

                <Box sx={{ textAlign: 'center', mb:3, fontSize:14, border:"1px solid #4728c4", borderRadius:4, backgroundColor: "#f4f2ff", color:"#4728c4", p:1 }}>
                  <Typography variant="body1" align="center" color="#4728c4" fontSize={14} whiteSpace={"pre-wrap"}
                  
                  >
                    {plan.description}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', mb: 0, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:1 }}>
                  <Typography variant="h4" fontSize={45} gutterBottom>
                    ${plan.prices[planTier][billingInterval].amount}
                  </Typography>
                  <Typography variant="h4" fontSize={22} gutterBottom>
                    /{billingInterval}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection:"column", gap:1, alignItems:"center"}}>   
                 
             
            

                <FormControl fullWidth sx={{ mb: 2 }}>
               
                  <Select
                    value={planTier}
                    onChange={(e) => handleTierChange(plan.id, e.target.value)}
                    sx={{ borderRadius: 4, fontSize: 14 }}
                  >
                    {Object.entries(tiers).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography variant="caption" fontSize={12} fontWeight={400} color="text.secondary" align="left">
                           Monthly Analyzed Accounts (MAAs)*
                        </Typography>  
                    
                </FormControl>


                </Box>

                <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                  {plan.features.map((feature) => (
                    <Box
                      component="li"
                      key={feature}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1
                      }}
                    >
                      <CheckCircleTwoTone sx={{fill:"#4728c4"}} fontSize="small" />
                      <Typography fontWeight={600} variant="body1">{feature}</Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>
              <CardActions>
                <Button 
                disabled={(plan.active && ( teamData.plan && teamData.plan.allowedMAAs && parseInt(planTier) == teamData.plan.allowedMAAs))}
                  fullWidth 
                  variant={plan.popular ? "contained" : "outlined"}
                  onClick={() => handleSubscribe(plan.prices[planTier][billingInterval].priceId)}
                  sx={{ mb: 2, borderRadius:30 }}
                >
                  {(plan.active && ( teamData.plan && teamData.plan.allowedMAAs && parseInt(planTier) == teamData.plan.allowedMAAs)) ? "Active" : 
                  ( plan.active && teamData.plan && teamData.plan.allowedMAAs && parseInt(planTier) > teamData.plan.allowedMAAs) ? "Upgrade" :  plan.active ? "Downgrade" : 
                  "Subscribe"}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
     


      </Grid>


    <Grid container spacing={2} sx={{mt:2}}>
        <Grid item xs={12} md={6}>
        <Typography variant="body2" align="left" gutterBottom fontWeight={700} fontSize={12}>
        *Monthly Analyzed Accounts are the number of unique accounts your team analyzes in a month. <br/>
        MAAs are platform-based - e.g. a creator’s TikTok and Instagram are 2 unique accounts.
    </Typography>
        </Grid>
    <Grid item xs={12} md={6}>
    <Typography variant="body1" align="right" gutterBottom fontWeight={700}>
    Need increased limits or multiple teams? <Link href="mailto:team@siftsy.xyz"><br/>Contact us</Link> for enterprise plans.
    </Typography>
    </Grid>
    </Grid>
   </Box>
  );
};

export default StripePlanPricing;